<template>
  <b-modal
    ref="mdlUsuarioAlterarSenha"
    title="Alterar Senha"
    ok-title="Salvar"
    cancel-title="Cancelar"
    size="sm"
    @ok.prevent="salvar"
    @hidden="limpar"
    @shown="validacao"
    centered
  >
    <b-form ref="frmUsuarioAlterarSenha">
      <b-form-group label="Senha:" label-for="usu-senha">
        <b-input-group>
          <b-form-input
            id="usu-senha"
            name="senha"
            :type="exibirSenha ? 'text' : 'password'"
            v-model="formulario.senha"
          ></b-form-input>

          <b-input-group-append>
            <b-button
              @click="exibirSenha = !exibirSenha"
              variant="outline-secondary"
              tabindex="-1"
            >
              <b-icon
                :icon="exibirSenha ? 'eye-slash-fill' : 'eye-fill'"
              ></b-icon>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group label="Confirmar senha:" label-for="usu-confirmar-senha">
        <b-input-group>
          <b-form-input
            id="usu-confirmar-senha"
            name="confirmarSenha"
            :type="exibirConfirmarSenha ? 'text' : 'password'"
            v-model="formulario.confirmarSenha"
          ></b-form-input>

          <b-input-group-append>
            <b-button
              @click="exibirConfirmarSenha = !exibirConfirmarSenha"
              variant="outline-secondary"
              tabindex="-1"
            >
              <b-icon
                :icon="exibirConfirmarSenha ? 'eye-slash-fill' : 'eye-fill'"
              ></b-icon>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-form>

    <usuario-gerador-senha @senha-gerada="senhaGerada"></usuario-gerador-senha>
  </b-modal>
</template>

<script>
import UsuarioService from "@/core/services/usuario.service";
import UsuarioGeradorSenha from "./UsuarioGeradorSenha.vue";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

import Swal from "sweetalert2";

export default {
  name: "usuario-alterar-senha",

  components: {
    UsuarioGeradorSenha,
  },

  data() {
    return {
      formulario: {
        id: null,
        senha: "",
        confirmarSenha: "",
      },
      exibirSenha: false,
      exibirConfirmarSenha: false,
    };
  },

  methods: {
    exibirModal(usuario) {
      if (!usuario) {
        Swal.fire({
          icon: "error",
          title: "Erro!",
          text: "Usuário não informado.",
        });

        return;
      }
      this.formulario.id = usuario.id;

      this.$refs.mdlUsuarioAlterarSenha.show();
    },

    async salvar() {
      await this.fv.validate().then((status) => {
        if (status === "Valid") {
          UsuarioService.alterarSenha(this.formulario)
            .then(() => {
              Swal.fire({
                icon: "success",
                title: "Sucesso!",
                text: "Senha alterada com sucesso.",
              });

              this.$refs.mdlUsuarioAlterarSenha.hide();
            })
            .catch((error) => {
              if (error.response.status === 422) {
                Object.entries(error.response.data.errors).forEach(
                  ([key, value]) => {
                    this.fv
                      .updateValidatorOption(key, "blank", "message", value[0])
                      .updateFieldStatus(key, "Invalid", "blank");
                  }
                );
              }
            });
        }
      });
    },

    validacao() {
      const frmUsuarioAlterarSenha = this.$refs.frmUsuarioAlterarSenha;

      this.fv = formValidation(frmUsuarioAlterarSenha, {
        fields: {
          senha: {
            validators: {
              notEmpty: {
                message: "Preenchimento obrigatório.",
              },
              stringLength: {
                min: 6,
                max: 20,
                message: "A senha deve conter entre 6 e 20 caracteres.",
              },
              blank: {},
            },
          },
          confirmarSenha: {
            validators: {
              notEmpty: {
                message: "Preenchimento obrigatório.",
              },
              identical: {
                message:
                  'Os campos "Senha" e "Confirmar Senha" devem ser iguais.',
                compare: function () {
                  return this.formulario.senha;
                },
              },
              blank: {},
            },
          },
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap(),
        },
      });
    },

    limpar() {
      this.formulario.id = null;
      this.formulario.senha = "";
      this.formulario.confirmarSenha = "";
      this.exibirSenha = false;
      this.exibirConfirmarSenha = false;
    },

    senhaGerada(senha) {
      this.formulario.senha = senha;
      this.formulario.confirmarSenha = senha;
    },
  },

  beforeDestroy: function () {
    if (this.fv) {
      this.fv.destroy();
    }
  },
};
</script>
