<template>
  <div>
    <b-button @click="gerar" size="sm">Gerador de senha</b-button>

    <b-modal
      ref="mdlUsuarioGeradorSenha"
      title="Gerador de Senha"
      cancel-title="Fechar"
      @show="nova"
      @ok="utilizar"
      hide-header
      footer-class="d-flex justify-content-center"
      centered
    >
      <div class="d-block text-center">
        <h1>{{ senha }}</h1>
      </div>

      <template #modal-footer="{ cancel, ok }">
        <b-button @click="cancel" variant="secondary" size="sm"
          >Fechar</b-button
        >

        <b-button @click="nova" variant="primary" size="sm"
          >Nova senha</b-button
        >
        <b-button
          @click="copiar"
          variant="info"
          size="sm"
          v-b-tooltip.click.blur="'Copiado!'"
          >Copiar</b-button
        >

        <b-button @click="ok" variant="success" size="sm">Utilizar</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      senha: "",
    };
  },

  methods: {
    gerar() {
      this.$refs.mdlUsuarioGeradorSenha.show();
    },

    nova() {
      this.senha = Math.floor(100000 + Math.random() * 900000).toString();
    },

    copiar() {
      navigator.clipboard.writeText(this.senha);
    },

    utilizar() {
      this.$emit("senha-gerada", this.senha);
      this.$refs.mdlUsuarioGeradorSenha.hide();
    },
  },
};
</script>
