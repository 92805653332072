<template>
  <b-modal
    ref="mdlUsuarioAssinaturaDigital"
    no-header
    @hidden="reset"
    footer-class="d-flex justify-content-center"
    body-class="d-flex justify-content-center"
  >
    <b-img :src="src" fluid thumbnail></b-img>

    <template #modal-footer="{ hide }">
      <b-button variant="primary" @click="hide()"> Fechar </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "usuario-assinatura-digital",
  data() {
    return {
      src: null,
    };
  },
  methods: {
    exibirAssinaturaDigital(assinatura) {
      const src = URL.createObjectURL(assinatura);
      this.src = src;
      this.$refs.mdlUsuarioAssinaturaDigital.show();
    },

    reset() {
      this.assinatura = null;
    },
  },
};
</script>
